export const faqList = [
  {
    id: 1,
    heading: `What is Heartfulness?`,
    body: `Heartfulness is a heart-centered approach to life, where you will be able to live each moment by the heart. It is to live naturally, in tune with the qualities of a heart enlightened and refined through spiritual practice. These qualities include compassion, sincerity, contentment, truthfulness, and forgiveness; attitudes such as generosity and acceptance; and the heart’s fundamental nature, which is…`,
    readmore: "https://heartfulness.org/en/faq/what-is-heartfulness/",
  },
  {
    id: 2,
    heading: `What will I achieve by practicing Heartfulness?`,
    body: `The path of Heartfulness has stages and destinations, but let’s look at this from another perspective: are they things to be ‘achieved’? For example, suppose that a son stands to inherit the fortune of his mother. Should the idea of receiving the inheritance ever become his goal? Should that be his motivation for maintaining a good relationship with his mother?……`,
    readmore:
      "https://heartfulness.org/en/faq/what-can-we-achieve-by-practicing-heartfulness/",
  },
  {
    id: 3,
    heading: `Who are the Heartfulness trainers and what enables them to impart Transmission?`,
    body: `Bus Routes : Take bus from Mahavir Chowk for Dundahera border and get down at Krishna Chowk. Anand Farms is a landmark on both sides of the road leading to the ashram
    E rickshaws and Autos are available from Bus stops. Shared  Autos from Old Gurgaon are available.  Nearest  Metro Station is  MG Road/ Sikanderpur and the nearest Rapid Metro Station is  Cyber City from which you can take autos to reach the Meditation center.`,
    readmore:
      "https://heartfulness.org/en/faq/who-are-the-heartfulness-trainers-and-what-enables-them-to-impart-transmission/",
  },
  {
    id: 4,
    heading: `When should we offer the Heartfulness Prayer?`,
    body: `Begin your morning meditation with a silent offering of the Heartfulness Prayer. Offer it once and start meditation. You may also offer the same prayer at night, just before going to sleep. At that time, repeat the prayer a few times, and allow yourself to become absorbed in the feelings that unfold within you. In this prayerful state, go to…….`,
    readmore:
      "https://heartfulness.org/en/faq/who-are-the-heartfulness-trainers-and-what-enables-them-to-impart-transmission/",
  },
  {
    id: 5,
    heading: `Will Heartfulness Meditation help me to sleep better?`,
    body: `Problems with sleep are endemic in modern urban society. Heartfulness will help you to become more in tune with natural cycles and thus regulate your sleep patterns. Here are some tips for getting a good night’s sleep. Rise early in the morning and spend a few minutes out in the sun, as this will balance the hormones serotonin and melatonin,…  `,

    readmore:
      "https://heartfulness.org/en/faq/who-are-the-heartfulness-trainers-and-what-enables-them-to-impart-transmission/",
  },
];
