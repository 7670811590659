import React, { useState } from "react";

import axios from "axios";

import moment from "moment";

import { useForm } from "react-hook-form";

import { ToastContainer, toast } from "react-toastify";

import bluehearticon from "../../assets/images/subpages/bluehearticon.png";

import guidepic from "../../assets/images/subpages/guide11.jpg";

import workshoppic from "../../assets/images/subpages/workshop-image.jpg";

import handblueicon from "../../assets/images/subpages/handblue-icon.png";

import { faqList } from "../../assets/data/faq";

import { Accordion } from "react-bootstrap";

import handheart from "../../assets/images/subpages/hand-heart.png";

import pdficon from "../../assets/images/subpages/pdf-icon.png";

import emailicon from "../../assets/images/about/email-icon.png";

import penicon from "../../assets/images/about/pen-icon.png";

import { Link } from "gatsby";


import "react-toastify/dist/ReactToastify.css";

const Experiencecontentpage = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();
  const [loading, setLoading] = useState(false);
  const onSubmit = (data) => {
    setLoading(true);

    const dataObj = {};
    const todayVal = moment(new Date()).format("MM-DD-YYYY h:mm:ss a");

    dataObj.submitteddate = todayVal;

    dataObj.emailid = data.email;
    dataObj.observations = data.observations;
    dataObj.projectname = "SA";

    axios
      .post(
        "https://g6iqtcvhyl.execute-api.ap-south-1.amazonaws.com/api/sa/experience",
        dataObj
      )
      .then((response) => {
        if (response.status === 200) {
          setLoading(false);
          toast.success("Form submitted successfully");
          reset();
        } else {
          setLoading(false);
          toast.error("something went wrong");
        }
      });
  };
  return (
    <section className="expericencecontentpage">
      <div className="container">
        <div id="wayexperience" className="row">
          <div className="col-md-12">
            <h4 className="theme-h4 theme-color font24"> The Way of Experience </h4>

            <p className="theme-p-tag experience-ptag">
              {" "}
              <b> Experience is the source from which real knowledge begins.</b>
            </p>
          </div>
        </div>
        <div className="row pad20" style={{ backgroundColor: "#f5f5f5" }}>
          <div className="col-md-6">
            <p className="theme-p-tag">
              {" "}
              We learn a lot from many sources like scriptures, spiritual
              lectures and modern books on spirituality. But as the lecture ends
              or we finish reading the book, we remain the same as before.
              Experience is the gold that backs the currency of knowledge.
              Without experience, all knowledge stays abstract and hollow. It is
              not yet ours.
            </p>
            <p className="theme-p-tag">
              {" "}
              Heartfulness is a path of experiencing our inner Self. It teaches
              us to dive deep into our heart, and be in a natural state of love,
              peace, and calmness. When practiced regularly, meditation helps us
              become in tune with our heart and develop the capacity to remain
              poised and balanced at all times.
            </p>
          </div>
          <div className="col-md-6">
            <iframe
              width="500"
              height="239"
              src="https://www.youtube.com/embed/f37wdNIPB_g"
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
            ></iframe>
          </div>
        </div>
        <div className="row">
          <div className="col-md-6 text-center mt-5">
            <p className="theme-p-tag">
              {" "}
              Heartfulness meditation is a practical technique that can be used
              in parallel with any other spiritual approach.
            </p>
            <br />
            <br />

            <div className="padd-tb40 mt-4">
              <img src={bluehearticon} alt="Universal Prayer" />
              <p className="theme-p-tag">
                {" "}
                Try Heartfulness meditation on your own <br /> and see how you
                feel.
              </p>
            </div>
          </div>
          <div className="col-md-6 text-center mt-5">
            <p className="theme-p-tag text-center">
              {" "}
              A meditation session with yogic transmission can help you to
              perceive the effects of meditation more deeply. We invite you to
              experience the unique benefits of yogic transmission in a guided
              session with a Heartfulness trainer. There are no charges for any
              of the Heartfulness meditation sessions.
            </p>
            <div className="padd-tb40">
              <img src={handblueicon} alt="Universal Prayer" />
              <p className="theme-p-tag">
                {" "}
                Click here to register <br /> for Heartfulness meditation with
                assistance.
              </p>
            </div>
          </div>
          <p className="text-center" style={{ fontSize: "16px" }}>
            {" "}
            <b>
              {" "}
              You don’t need faith or knowledge to experience! Simply do it and
              feel!{" "}
            </b>{" "}
          </p>
          <hr />
        </div>

        <div id="heartfulnessown" className="row">
          <h4 className="theme-h4 theme-color font24"> Try Heartfulness on your own</h4>
          <p className="theme-p-tag experience-ptag">
            {" "}
            <b>  Deepen your experience and appreciation of life through this simple
              daily practice of Heartfulness.</b>
          </p>
        </div>

        <div className="row pad20" style={{ backgroundColor: "#f5f5f5" }}>
          {/* <div className="col-md-12">
            <p className="theme-p-tag"> Create the environment</p>
            <p className="theme-p-tag">
              {" "}
              Find a place where you won’t be disturbed. Turn off your phones,
              televisions, radios and any other sources of distraction. If you
              need to, tell your room-mates or family members that you will not
              be available for 30 minutes. It is recommended that the
              Heartfulness practice is followed for at least seven days to feel
              the effects of meditation in a tangible way.
            </p>
          </div> */}
          <div className="col-md-6">
            <iframe
              width="595"
              height="260"
              src="https://www.youtube.com/embed/er2rNCB-wbw"
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
            ></iframe>
            <div className="mg-top20">
              <div className="row">
                <div className="col-md-6 padd-t30">
                  {/* <p className="text-center">
                    <a
                      href="//cdn-prod.heartfulness.org/hfn/files/try-heartfulness-on-your-own-heartfulness-guided-relaxation.pdf"
                      target="_blank"
                      className=""
                      rel="noopener noreferrer"
                    >
                      {" "}
                      <img src={pdficon} alt="Universal Prayer" />{" "}
                    </a>
                  </p> */}
                  <p className="text-center">
                    <button type="button" className="text-decore-none btn btn-primary"

                      style={{ color: "#FFFFFF", padding: "6px 67px" }}>
                                                <Link
                      to="//cdn-prod.heartfulness.org/hfn/files/heartfulness-guided-relaxation.pdf"
                      target="_blank" rel="noopener" className="white-text"
                    >
                      {" "}
                      Guided  </Link>
                    </button>
                  </p>
                </div>
                <div className="col-md-6 padd-t30">
                  {/* <p className="text-center">
                    <a
                      href="//cdn-prod.heartfulness.org/hfn/files/try-heartfulness-on-your-own-rack-card.pdf"
                      target="_blank"
                      className=""
                      rel="noopener noreferrer"
                    >
                      {" "}
                      <img src={pdficon} alt="Universal Prayer" />{" "}
                    </a>
                  </p> */}

                  <p className="text-center">
                    <button type="button" className="text-decore-none btn btn-primary"
                      style={{ color: "#FFFFFF", padding: "6px 48px" }}>
                        <Link
                      to="//cdn-prod.heartfulness.org/hfn/files/try-heartfulness-on-your-own-rack-card.pdf"
                      target="_blank" className="white-text"
                      rel="noopener noreferrer">
                      {" "}
                     
                      Heartfulness Rack Card  </Link> 
                    </button>
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <p className="theme-p-tag experience-ptag">
              {" "}
              <b> Relax </b>{" "}
            </p>
            <p className="theme-p-tag">
              {" "}
              Sit comfortably and follow the Heartfulness relaxation. This can
              be done by people of all ages.
            </p>
            <p className="theme-p-tag experience-ptag">
              {" "}
              <b> Meditate </b>{" "}
            </p>
            <p className="theme-p-tag">
              {" "}
              Gently close your eyes and suppose that a Divine Light is
              illuminating your heart from within. Rather than trying to
              visualize it, simply tune in to your heart and be open to any
              experience that you may have. Do this for 30 minutes. If your mind
              wanders, gently bring your attention back to your heart. This can
              be done by anyone who has completed the age of 15.
            </p>
          </div>
        </div>

        <div className="row pad20">
          <div className="col-md-6">
            <p className="theme-p-tag">
              {" "}
              Once you feel yourself coming out of the meditation naturally,
              spend a few minutes to write down what you observed in a journal.
              Keeping a journal of your observations is highly recommended. It
              provides a practical record of the changes brought about by this
              meditation over time.
            </p>
            <p className="theme-p-tag">
              {" "}
              <b> Points for reflection: </b>{" "}
            </p>
            <p className="theme-p-tag"> How do I feel after meditation?</p>
            <p className="theme-p-tag">
              {" "}
              Where was my attention during this meditation?{" "}
            </p>
            <p className="theme-p-tag">
              {" "}
              How did it feel to focus on the heart instead of the mind?{" "}
            </p>
            <p className="theme-p-tag">
              {" "}
              When you feel inspired to explore this further, you might consider
            </p>
            <div className="text-center padd-tb40">
              <img src={handblueicon} alt="Universal Prayer" />
              <p className="theme-p-tag">
                {" "}
                Practicing Heartfulness with the assistance <br /> of yogic
                transmission.
              </p>
            </div>
          </div>
          <div className="col-md-6" >
           
          <p className="theme-p-tag">
              {" "}
              For any further information related to share your experience in heartfulness 
              </p>
              <p className="theme-p-tag"><b>Please contact </b> </p>
              <p className="theme-p-tag">  
              <a className="" href="mailto:devanr@volunteer.heartfulness.org ">devanr@volunteer.heartfulness.org</a> <br/> 
              <a className="" href="mailto: rsa.secretary@heartfulness.org "> rsa.secretary@heartfulness.org </a> <br/> 
              <a className="" href="mailto: za.secretary@srcm.org ">   za.secretary@srcm.org</a>
            
              </p>

              <img
              className="mg-bottom20"
              src={handheart}
              alt="Universal Prayer"
            />
          </div>
        </div>
        <div>
          <ToastContainer
            position="top-right"
            autoClose={3000}
            newestOnTop={false}
            hideProgressBar
            closeOnClick
            rtl={false}
          />
        </div>
        <hr />

        <div id="heartfulnessassitance" className="row">
          <div className="col-md-12">
            <h4 className="theme-h4 theme-color"> Try Heartfulness with Assistance </h4>
            <p className="theme-p-tag experience-ptag">
              {" "}
              A meditation session with yogic transmission can help you to
              perceive the effects of meditation more deeply. We invite you to
              experience the unique benefits of yogic transmission in a guided
              session with a Heartfulness trainer. There are no charges for any
              of the Heartfulness meditation sessions.
            </p>
          </div>
        </div>

        <div className="row pad20" style={{ backgroundColor: "#f5f5f5" }}>
          <div className="col-md-6 padd-35">
            <img
              className="mg-bottom20"
              src={guidepic}
              alt="Universal Prayer"
            />
            <p className="theme-p-tag">
              {" "}
              Please register using the ‘Heartfulness Workshop Form’ when you
              are ready to experience Heartfulness meditation with the
              assistance of yogic transmission. So much can be said about its
              benefits, but your experience will reveal more than volumes of
              literature could possibly convey. You will be contacted by a
              Heartfulness trainer through email and will be guided through
              three or four meditation sessions on consecutive days. The
              meditation sessions can be conducted remotely or in person, as
              mutually convenient.
            </p>
            <p className="theme-p-tag">
              {" "}
              There are no charges for the Heartfulness practice, the meditation
              sessions, or any teaching offered through this website. You will
              be subscribed to the Heartfulness newsletter to help you proceed
              on your journey.{" "}
            </p>
          </div>
          <div className="col-md-6 padd-t40 padd-25">
            <div className="contact-trainer-form">
              <div className="hfn-home-offering">
                <p className="theme-p-tag padd-tb20 text-center experience-ptag">
                  <b>Where to meditate?</b>
                </p>
                <hr style={{ color: "#707070", height: "2px" }} />
                <p className="theme-p-tag padd-tb20 experience-ptag">
                  Experience meditation, ask questions, and meet other
                  like-minded seekers. <br /><br />There are{" "}
                  <span class="theme-color">6,000 HeartSpots</span> worldwide.<br /><br />
                  Find yours here:
                </p>

                <div className="panel panel-default1">
                  <div className="panel-body text-center map_padding">
                    <div id="map" className="map where-to-meditate-map">
                      <Link
                        to="https://goo.gl/maps/swXynTnYVCcqxFja6"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <img
                          loading="lazy"
                          className="where-to-meditate-map-img alignnone"
                          src="//cdn-prod.heartfulness.org/hfn/images/2018/april/kanha-map-loc.png"
                          alt="kanha shanti vanam address"
                          width="398"
                          height="368"
                        />
                      </Link>
                    </div>
                  </div>
                </div>

                <div className="text-center padd-t30 mt-4 mb-4">
                  <Link
                    type="button"
                    className="btn btn-blue"
                    to="https://heartspots.heartfulness.org/"
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{ borderRadius: "25px" }}
                  >
                    Find your trainer
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
        <hr />
        <div id="heartfulnessworkshop" className="row">
          <div className="col-md-12">
            <h4 className="theme-h4 theme-color"> Heartfulness Workshops</h4>
            <p className="theme-p- experience-ptag">
              {" "}
              Heartfulness meditation workshops are held at a number of
              locations worldwide, in a variety of formats and languages.
            </p>
          </div>
        </div>

        <div className="row" style={{ backgroundColor: "#f5f5f5", padding: "50px" }}>
          <div className="col-md-8">
            <p className="theme-p-tag mt-4">
              {" "}
              These sessions offer a practical experience of Heartfulness
              meditation, which you can then integrate into your daily life.
              Please send an email to A{" "}
              <Link
                to="mailto:workshops@heartfulness.org"
                target="_blank"
                rel="noreferrer"
                className="text-decore-none"
              >
                {" "}
                <span className="orange-text">
                  {" "}
                  workshops@heartfulness.org{" "}
                </span>{" "}
              </Link>{" "}
              if you would like more information.
            </p>
            <p className="theme-p-tag">
              If you would like to learn how to conduct Heartfulness workshops
              in your area, or would like to participate in the Heartfulness
              initiative in any way, please send an email to{" "}
              <Link
                to="mailto:join@heartfulness.org"
                target="_blank"
                rel="noreferrer"
                className="text-decore-none"
              >
                {" "}
                <span className="orange-text"> join@heartfulness.org </span>
              </Link>
              .
            </p>
          </div>
          <div className="col-md-4">
            <img src={workshoppic} alt="Universal Prayer" />
          </div>
        </div>
        <hr />

        <div className="row pad20">
          <div className="col-md-12">
            <h4 className="theme-h4 theme-color">FAQ Questions </h4>



            <Accordion className="exp-faq-accordion-wrapper">
              {faqList.map((item) => {
                return (
                  <Accordion.Item className="exp-accordian" eventKey={item.id} key={item.id}>
                    <Accordion.Header className="exp-body accordion-header">
                      {item.heading}
                    </Accordion.Header>
                    <Accordion.Body >
                      <div 
                        dangerouslySetInnerHTML={{ __html: item.body }}
                      ></div>
                      <span>
                        {" "}
                        <Link
                          to={item.readmore}
                          target="_blank"
                          rel="noreferrer"
                        >
                          Read more
                        </Link>{" "}
                      </span>
                    </Accordion.Body>
                  </Accordion.Item>
                );
              })}
            </Accordion>
          </div>
        </div>
      </div>
    </section>
  );
};
export default Experiencecontentpage;
