import React from "react";

import Seo from "../components/seo/index";

import Layout from "../layout/index";

import Experienceblueicon from "../components/subpages/experienceblueicon";

import Experiencebreadcreumb from "../components/subpages/experiencebreadcumb";

import Experiencecontentpage from "../components/subpages/experiencecntent";

// markup
const experienceheartfulnessPage = () => {
  return (
    <div>
      <Seo title="Experience Heartfulness" />

      <Layout isSticky>
        <section>
          <Experiencebreadcreumb />
        </section>

        <section>
          <Experienceblueicon />
        </section>

        <section>
        <Experiencecontentpage />
        </section>
      </Layout>
    </div>
  );
};

export default experienceheartfulnessPage;
