import React from "react";
import Breadcrumb from "react-bootstrap/Breadcrumb";

export default function Experiencebreadcreumb() {
  return (
    <div className="breadcumb-sub-page about-banner subpage-padding">
      <div className="container">
        <div className="row">
          <div className="col-md-12 pad40">
            <h3 className="heading-wrapper about-breadcub-title text-center " >
              {" "}
              Experience Heartfulness{" "}
            </h3>

            <div classname="cent-item">
              <Breadcrumb>
                <Breadcrumb.Item href="/#home"><span  className="home-color-offerings">Home</span></Breadcrumb.Item>
                <Breadcrumb.Item active> <span  className="home-color-offerings">Experience Heartfulness</span></Breadcrumb.Item>
              </Breadcrumb>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
