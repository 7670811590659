import React from "react";

import blueleaficon from "../../assets/images/subpages/blue-leaf-icon.png";

import bluehearticon from "../../assets/images/subpages/bluehearticon.png";

import handblueicon from "../../assets/images/subpages/handblue-icon.png";

import globeicon from "../../assets/images/subpages/globe-blue.png";

import { Link } from "gatsby";

export default function Experienceblueicon() {
  return (
    <section className="expericencecontentpage ">
      <div className="container">
        <div className="row">
          <div className="col-md-3 padd-tb40">
            <div className="cent-item">
            <Link to="#wayexperience" className="text-decore-none">
              <img src={blueleaficon} alt="Universal Prayer" />
            
              <p className="theme-p-tag theme-color mg-top20" >
                {" "}
                The Way of Experience {" "}
              </p>
              </Link>
            </div>
          </div>
          <div className="col-md-3 padd-tb40">
            <div className="cent-item">
            <Link to="#heartfulnessown" className="text-decore-none">
              <img src={bluehearticon} alt="Universal Prayer" />
            
              <p className="theme-p-tag theme-color mg-top20">
                {" "}
                 Heartfulness on your own{" "}
              </p>
              </Link>
            </div>
          </div>
          <div className="col-md-3 padd-tb40">
            <div className="cent-item">
            <Link to="#heartfulnessassitance" className="text-decore-none">
              <img src={handblueicon} alt="Universal Prayer"  />
            
              <p className="theme-p-tag theme-color mg-top20">
                {" "}
              Heartfulness With Assistance
              </p>
              </Link>
            
            </div>
          </div>
          <div className="col-md-3 padd-tb40">
            <div className="cent-item">
            <Link to="#heartfulnessworkshop" className="text-decore-none">
              <img src={globeicon} alt="Universal Prayer" />
          
              <p className="theme-p-tag theme-color mg-top20">
                {" "}
                 Heartfulness Workshops{" "}
              </p>
              </Link>
             
            </div>
          </div>
        </div>
        <hr/>
      </div>
    </section>
  );
}
